<template>
    <Guest :title="t('register5')">
        <div class="py-8">
            <form v-on:submit.prevent="navigateNext">
                <QuestionWithOptions v-model:value="accept_newsletter" v-model:errors="errors.accept_newsletter" :question_container="{ question: '', options: [{ id: 0, name: t('no') }, {id: 1, name: t('yes')}]}" />
                <div class="mt-8">
                    <button type="submit" class="button w-full">
                        {{ t('next_step') }}
                    </button>
                </div>
            </form>
        </div>
    </Guest>
</template>

<script>
import router from "@/router";
import Guest from "@/wrappers/Guest";
import QuestionWithOptions from "@/components/forms/QuestionWithOptions";
import { register_form_to_storage } from "@/helpers/storage/RegisterFormHelper";
import { register_validation } from "@/helpers/api/RegistrationHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Register5",
    components:{
        Guest,
        QuestionWithOptions,
    },
    computed:{
        accept_newsletter: register_form_to_storage('accept_newsletter'),
    },
    setup() {
        return useI18n({
            viewPrefix: "Register"
        });
    }, 
    data(){
        return {
            errors: {
                accept_newsletter: [],
            }
        }
    },
    methods: {
        navigateNext() {
            setLoading(true);

            const data = {
                accept_newsletter: this.accept_newsletter, 
            }

            register_validation(data)
                .then((result)=> {
                    if (result.success){
                        router.push({name: "register.6"});
                    } else {
                        this.errors = result.errors;
                    }
                })
                .catch((error) => {
                    messages.error(this.t('messages.something_went_wrong', {error: error.message}));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>

<style scoped>

</style>
